<template>
  <div id="banlancesheet">
    <v-card>
      <v-card-title>
        貸借対照表
      </v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="customer_id"
            placeholder="取引先"
            :items="customerIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="ccy_id"
            :items="currencies"
            outlined
            dense

            placeholder="通貨"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="1"
          @click="reduceMonth"
        >
          <v-icon class="me-1">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-col>
        <v-col cols="2">
          <label>{{ selectedMonth.substring(0, 4) }}年{{ selectedMonth.substring(4,6) }}月</label>
        </v-col>
        <v-col
          v-if="selectedMonth < currentMonth"
          cols="1"
        >
          <v-icon
            class="me-1"
            @click="addMonth"
          >
            {{ icons.mdiChevronRight }}
          </v-icon>
        </v-col>
        <v-col
          v-if="selectedMonth >= currentMonth"
          cols="1"
        >
        </v-col>
        <v-col
          cols="12"
          md="3"
          style="text-align:right"
        >
          <v-btn
            color="secondary"
            outlined
            :loading="exportStatus"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        ref="MthlyProdStock"
        :headers="headers"
        :items="tmBalanceSheet"
        item-key="no"
        :items-per-page="-1"
      >
        <template v-slot:[`item.lm_balance`]="{ item }">
          <label>{{ formatPrice(item.lm_balance) }}</label>
        </template>
        <template v-slot:[`item.ar_amount`]="{ item }">
          <!-- <label>{{ formatPrice(item.ar_amount) }}</label> -->

          <a
            href="javascript:void(0);"
            @click="pushArList(item)"
          >{{ formatPrice(item.ar_amount) }}</a>
        </template>
        <template v-slot:[`item.depo_amount`]="{ item }">
          <!-- <label>{{ formatPrice(item.depo_amount) }}</label> -->
          <a
            href="javascript:void(0);"
            @click="pushDepositList(item)"
          >{{ formatPrice(item.depo_amount) }}</a>
        </template>
        <template v-slot:[`item.tm_balance`]="{ item }">
          <label>{{ formatPrice(item.tm_balance) }}</label>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiExportVariant,
  mdiPencil,
} from '@mdi/js'
import {
  mapMutations, mapActions, mapState,
} from 'vuex'

// import ProdMthlyTransferred from './ProdMthlyTransferred.vue'
// import MatMthlyTransferred from './MatMthlyTransferred.vue'
// import CruMthlyTransferred from './CruMthlyTransferred.vue'

export default {
  components: {
    // ProdMthlyTransferred,
    // MatMthlyTransferred,
    // CruMthlyTransferred,
  },
  data: () => ({

    custIds: [],
    customer_id: '',
    ccy_id: 0,
    exportStatus: false,

    tmBalanceSheet: [],

    headers: [
      {
        text: 'No.',
        align: 'left',
        value: 'no',
        width: '5%',
        fixed: true,
      },

      {
        text: '取引先',
        align: 'left',
        value: 'cust_abbr',
        width: '15%',
        fixed: true,
      },
      {
        text: '通貨',
        align: 'left',
        value: 'ccy_code',
        width: '10%',
        fixed: true,
      },
      {
        text: '前月残高',
        value: 'lm_balance',
        align: 'right',
        width: '17%',
        fixed: true,
      },
      {
        text: '当月売掛',
        value: 'ar_amount',
        align: 'right',
        width: '17%',
        fixed: true,
      },
      {
        text: '当月入金',
        value: 'depo_amount',
        align: 'right',
        width: '17%',
        fixed: true,
      },
      {
        text: '当月残高',
        value: 'tm_balance',
        align: 'right',
        width: '17%',
        fixed: true,
      },

    ],

    icons: {
      mdiChevronLeft,
      mdiChevronRight,
      mdiExportVariant,
      mdiPencil,
    },
    currentMonth: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 7).replace('-', ''),

  }),

  computed: {

    ...mapState('customerStore', ['customerIds']),
    ...mapState('currencyStore', ['currencies']),
    ...mapState('balanceStore', ['balanceSheet']),

    selectedMonth() {
      return this.$route.params.month
    },
    formatPrice() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return parseFloat(data).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },

  watch: {
    Stocktabs(newValue) {
      this.$router.push({
        query: {
          selTab: newValue,
        },
      })
    },
  },

  created() {
    console.log('created')
    this.loadQuery()
    this.changeRouteQuery()

    // this.loadContractIds()
    // this.loadProductIds()
    // this.loadCustomerIds()
    this.loadData()
  },

  methods: {
    // ...mapMutations('monthlyStockStore', ['clearMonthlyStock']),
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('currencyStore', ['loadCurrencyList']),
    ...mapActions('balanceStore', ['loadBalanceSheet']),

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.customer_id = this.$route.query.customer_id ?? ''
      this.ccy_id = this.$route.query.ccy_id ?? ''
    },
    clearCache() {
      this.clearMonthlyStock()
    },

    pushArList(item) {
      const currentDate = new Date(this.$route.params.month.replace(/^(\d{4})+/g, '$1-'))
      const startDay = new Date(currentDate.setDate(1)).toISOString().substring(0, 10)
      const endDay = new Date(new Date(currentDate.setMonth(currentDate.getMonth() + 1)).setDate(0)).toISOString().substring(0, 10)
      this.$router.push({
        name: 'ar-list',
        query: {
          cust_id: item.cust_id,
          startDate: startDay,
          endDate: endDay,
        },
      })
    },

    pushDepositList(item) {
      const currentDate = new Date(this.$route.params.month.replace(/^(\d{4})+/g, '$1-'))
      const startDay = new Date(currentDate.setDate(1)).toISOString().substring(0, 10)
      const endDay = new Date(new Date(currentDate.setMonth(currentDate.getMonth() + 1)).setDate(0)).toISOString().substring(0, 10)
      this.$router.push({
        name: 'deposit-list',
        query: {
          cust_id: item.cust_id,
          startDate: startDay,
          endDate: endDay,
        },
      })
    },

    downloadCSV() {

    },

    reduceMonth() {
      const date = new Date(`${this.selectedMonth.substring(0, 4)}-${this.selectedMonth.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() - 1)).toISOString().substring(0, 7).replace('-', '')
      this.$router.push({
        name: 'balancesheet-list',
        params: {
          month: selMonth,
        },
      })
      this.reLoadData()
    },
    addMonth() {
      const date = new Date(`${this.selectedMonth.substring(0, 4)}-${this.selectedMonth.substring(4, 6)}`)
      const selMonth = new Date(date.setMonth(date.getMonth() + 1)).toISOString().substring(0, 7).replace('-', '')
      if (selMonth <= this.currentMonth) {
        this.$router.push({
          name: 'balancesheet-list',
          params: {
            month: selMonth,
          },
        })
        this.reLoadData()
      }
    },

    reLoadData() {
      this.setOverlayStatus(true)
      this.loadBalanceSheet(this.$route.params.month).then(() => {
        this.tmBalanceSheet = this.balanceSheet
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCustomerIds(),
        this.loadCurrencyList(),
        this.loadBalanceSheet(this.$route.params.month),

      ]).then(() => {
        console.log('balanceSheet:', this.balanceSheet)
        this.tmBalanceSheet = this.balanceSheet
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
